import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { orderBy } from 'lodash';
import CapsuleTab from './CapsuleTab';
import Button from '../Button';
import 'react-calendar/dist/Calendar.css';
import { format, addDays, endOfDay } from 'date-fns/fp';
import Icon from '../Icon';
import Drawer from '../Drawer';
import { differenceInDays } from 'date-fns';
import Spinner from '../Spinner';
import DrawerAccordion from '../DrawerAccordion';
import { ValidateEmail } from '../../helper';

import { QuickPriceCalculator, QuickPriceCalculatorType } from '../../semshared/pricelist/quickprice';
import { BaseCapsule, CapsuleAd, CapsuleTabContainer, ClickOverlay } from './CapsuleComponents';
import CalendarSection from './sections/CalendarSection';
import GuestSection from './sections/GuestsSection';
import MeetingRoomsSectionPopup from './sections/MeetingRoomsSectionPopup';
import MeetingRoomsSectionDrawer from './sections/MeetingRoomsSectionDrawer';
import RoomsSection from './sections/RoomsSection';
import { position } from 'polished';
import { Availability, AvailabilityType } from '../../semshared/availability/availability';
import { context, trace } from '@opentelemetry/api';

import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";

interface CapsuleProps {
  label: string;
  shadowRoot: ShadowRoot | null;
  formState: string | null;
  platformUrl: string;
  hotelView: string | null;
}

export interface AddonProduct {
  visible: boolean;
  checked: boolean;
  sku: string;
  name: string;
  header: string;
  details: string;
  isAddon: boolean;
  isDeduction: boolean;
  lookupCode: string;
  recurring: string;
  count: number;
}

export interface AddonFacility {
  visible: boolean;
  checked: boolean;
  sku: string;
  name: string;
  header: string;
  details: string;
  lookupCode: string;
  recurring: string;
  count: number;
}

export interface Day {
  day: number;
  overnightGuests: number;
  totalGuests: number;
  occupancy: string[];
  seating: string[];
}

export interface IFormState {
  start: Date;
  end: Date;
  duration: string;
  meetingRooms: number;
  prevdayGuests: number;
  rooms: number;
  price: string;
  placeName: string;
  placeLat: number;
  placeLng: number;
  days: Day[];
}

type ValuePiece = Date | null;

const initialFormState = {
  start: addDays(1, new Date()),
  end: addDays(2, new Date()),
  duration: 'Ganztag',
  meetingRooms: 1,
  days: [
    {
      day: 0,
      overnightGuests: 0,
      totalGuests: 0,
      occupancy: ['FULLDAY'],
      seating: ['UFORM'],
    },
    {
      day: 1,
      overnightGuests: 0,
      totalGuests: 0,
      occupancy: ['FULLDAY'],
      seating: ['UFORM'],
    },
  ],
  rooms: 0,
  prevdayGuests: 0,
  placeName: new URLSearchParams(window.location.search).get('place') || 'Frankfurt am Main, Deutschland',
  placeLat: 50.110924,
  placeLng: 8.682127,
  price: '0'
};

const parseState = (s: string) => {
  try {
    const state = JSON.parse(s);
    state.start = new Date(state.start);
    state.end = new Date(state.end);
    return state;
  } catch (e) {
    return initialFormState;
  }
}

const Capsule = ({
  label,
  shadowRoot,
  formState: s,
  platformUrl,
  hotelView,
}: CapsuleProps) => {
  const isMobileSize = () => {
    return document.documentElement.clientWidth < 920;
  };

  const isHotelView = hotelView === 'true' || hotelView === '1';

  const [totalPriceNet, setTotalPriceNet] = React.useState<string | null>(null);
  const [totalPriceGross, setTotalPriceGross] = React.useState<string | null>(null);

  const [popupShow, setPopupShow] = React.useState<string | null>(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [reservationStatus, setReservationStatus] = React.useState<string>('READY');
  const [emailVerificationCode, setEmailVerificationCode] = React.useState('');
  const [emailVerificationCodeInvalid, setEmailVerificationCodeInvalid] = React.useState(false);

  const [formState, setFormState] = React.useState<IFormState>(s ? parseState(s) as IFormState : initialFormState);
  const prevFormStateRef = useRef<IFormState>(initialFormState);

  const [popupExpanded, setPopupExpanded] = React.useState(false);
  const [serviceTypes, setServiceTypes] = React.useState<any[]>([]);
  const [serviceTypesError, setServiceTypesError] = React.useState<string>('');
  const [quickPriceCalculator, setQuickPriceCalculator] = React.useState<QuickPriceCalculatorType | null>(null);
  const [availabilityChecker, setAvailabilityChecker] = React.useState<AvailabilityType | null>(null);
  const [meetingRoomsDay, setMeetingRoomsDay] = React.useState(0);
  const [errors, setErrors] = React.useState<any>({});

  const [calValue, onChange] = useState<ValuePiece | [ValuePiece, ValuePiece]>([formState.start, formState.end]);

  const _initDailyAddons = (serviceTypes: any, service: any) => {
    const aaf: AddonFacility[] = [];
    const aap: AddonProduct[] = [];

    for (const serviceType of serviceTypes) {
      for (const addonFacility of serviceType.addonFacilities || []) {
        if (!aaf.find(f => f.sku === addonFacility.sku)) {
          aaf.push({
            ...addonFacility,
            visible: false,
            checked: false,
            count: 1,
          });
        }
      }
      for (const addonProduct of serviceType.addonProducts || []) {
        if (!aap.find(p => p.sku === addonProduct.sku)) {
          aap.push({
            ...addonProduct,
            visible: false,
            checked: false,
            count: 1,
          });
        }
      }
    }
    const result = {
      addonFacilities: _serviceTypeApplyAddonFacilitiesVisibility(service, orderBy(aaf, 'header', 'asc')),
      addonProducts: _serviceTypeApplyAddonProductsVisibility(service, orderBy(aap, 'header', 'asc')),
    };
    return result;
  };
  const _serviceTypeApplyAddonFacilitiesVisibility = (service: any, addons: AddonFacility[]) => {
    addons.forEach(a => {
      a.visible = service.addonFacilities?.findIndex((af: any) => af.sku === a.sku) >= 0;
    });
    return addons;
  };
  const _serviceTypeApplyAddonProductsVisibility = (service: any, addons: AddonProduct[]) => {
    addons.forEach(a => {
      a.visible = service.addonProducts?.findIndex((ap: any) => ap.sku === a.sku) >= 0;
    });
    return addons;
  };

  const onCalChanged = (value: ValuePiece | [ValuePiece, ValuePiece]) => {
    if (Array.isArray(value) && value.length === 2) {
      const days = differenceInDays(value[1] || formState.end, value[0] || formState.start);
      let daysArray = [];
      if (days >= formState.days.length) {
        daysArray = [
          ...formState.days,
          ...Array.from({ length: days - formState.days.length + 1 }, (_, i) => ({
            day: i + formState.days.length,
            overnightGuests: 0,
            totalGuests: 0,
            occupancy: Array.from({ length: formState.meetingRooms }, (_, i) => 'FULLDAY'),
            seating: Array.from({ length: formState.meetingRooms }, (_, i) => 'UFORM'),
          })),
        ];
      } else {
        daysArray = formState.days.slice(0, days + 1);
      }
      setFormState({
        ...formState,
        start: (Array.isArray(value) && value[0]) || endOfDay(formState.start),
        end: (Array.isArray(value) && value[1]) || endOfDay(formState.start),
        days: daysArray,
      });
      onChange(value);
      if (!isHotelView) {
        setPopupShow('place');
      }
    }
  };
  
  useEffect(() => {
    if (prevFormStateRef.current.meetingRooms > formState.meetingRooms) {
      setFormState({
        ...formState,
        days: formState.days.map(day => ({
          ...day,
          occupancy: day.occupancy.slice(0, formState.meetingRooms),
        })),
      });
    } else if (prevFormStateRef.current.meetingRooms < formState.meetingRooms) {
      setFormState({
        ...formState,
        days: formState.days.map(day => ({
          ...day,
          occupancy: [...day.occupancy, ...Array.from({ length: formState.meetingRooms - day.occupancy.length }, (_, i) => 'FULLDAY')],
        })),
      });
    }
  }, [formState.meetingRooms]);

  useEffect(() => {
    sessionStorage.setItem('formState', JSON.stringify(formState));
    prevFormStateRef.current = JSON.parse(JSON.stringify(formState));
    const myEvent = new CustomEvent("onSeminargoWidgetStateChange", {
      detail: {
        state: formState,
      }
    });
    document.dispatchEvent(myEvent);
  }, [formState]);

  const [capsuleWrapperPositionLeft, setCapsuleWrapperPositionLeft] = useState<number | undefined>(undefined);
  const [capsuleWrapperPositionTop, setCapsuleWrapperPositionTop] = useState<number | undefined>(undefined);
  const [capsuleWrapperWidth, setCapsuleWrapperWidth] = useState<number | undefined>(undefined);
  const capsuleWrapperRef = React.useRef<HTMLDivElement>(null);

  const [widgetClickedOnce, setWidgetClickedOnce] = useState(false);

  function handleResize() {
    setCapsuleWrapperPositionLeft(capsuleWrapperRef.current?.getBoundingClientRect().left);
    setCapsuleWrapperPositionTop(
      (capsuleWrapperRef.current?.getBoundingClientRect().top || 0) +
        (capsuleWrapperRef.current?.getBoundingClientRect().height || 0) +
        window.scrollY,
    );
    setCapsuleWrapperWidth(capsuleWrapperRef.current?.getBoundingClientRect().width);
  }

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    setInterval(() => {
      handleResize();
    }, 100);
  }, []);

  return (
    <>
      {popupShow === 'termin' && (
        <div
          style={{
            borderTopLeftRadius: '32px',
            borderTopRightRadius: '32px',
            position: 'absolute',
            width: capsuleWrapperWidth,
            backgroundColor: 'white',
            height: (capsuleWrapperRef.current?.getBoundingClientRect().height || 0) + 'px',
          }}
        ></div>
      )}
      <div ref={capsuleWrapperRef} style={{ position: 'relative' }} onClick={() => {
        if (!widgetClickedOnce) {
          setWidgetClickedOnce(true);
        }
      }}>
          <>
            <BaseCapsule
              className="SeminargoCapsule"
              expanded={true}
              slimMode={false}
            >
                <>
                  <CapsuleTabContainer>
                  
                    <CapsuleTab
                      name="termin"
                      label="Termin"
                      icon="calendar"
                      description={
                        (formState.start && formState.end && format('dd.MM.', formState.start) + ' - ' + format('dd.MM.yy', formState.end)) || 'keine'
                      }
                      popupShow={popupShow}
                      expanded={popupExpanded}
                      setPopupShow={setPopupShow}
                      capsuleLeftPosition={capsuleWrapperPositionLeft}
                      capsuleTopPosition={capsuleWrapperPositionTop}
                      capsuleWidth={capsuleWrapperWidth}
                      popupContent={<CalendarSection availabilityChecker={availabilityChecker} quickPriceCalculator={quickPriceCalculator} vertical={false} fullScreen={false} drawer={false} onCalChanged={onCalChanged} calValue={calValue} />}
                    />
                    {!isHotelView && <CapsuleTab
                        name="place"
                        label="Ort"
                        icon="place"
                        description={formState.placeName}
                        setPopupShow={setPopupShow}
                        popupShow={popupShow}
                        expanded={popupExpanded}
                        capsuleLeftPosition={capsuleWrapperPositionLeft}
                        capsuleTopPosition={capsuleWrapperPositionTop}
                        capsuleWidth={capsuleWrapperWidth}
                        popupContent={<div style={{ padding: 20}}>
                        <Autocomplete
                          options={{
                            types: ["(regions)"],
                          }}
                          style={{
                            backgroundColor: 'rgb(249, 250, 251)',
                            border: '1px solid rgb(209, 213, 219)',
                            padding: '0.625rem',
                            borderRadius: '0.5rem'
                          }}
                          apiKey={'AIzaSyAqGm1p5W-J2ghfJ4eSJq4yjpMS3yett7Y'}
                          onPlaceSelected={(place) => {
                            //const urlParams = new URLSearchParams(window.location.search);
                            setFormState({
                              ...formState,
                              placeName: place.formatted_address,
                              placeLat: place.geometry.location.lat(),
                              placeLng: place.geometry.location.lng()
                            })
                            setPopupShow('meeting_rooms')
                            //window.location.href = window.location.href.split('?')[0] + '?lat=' + place.geometry.location.lat() + '&lng=' + place.geometry.location.lng() + '&place=' + place.formatted_address;
                          }}
                        />
                        </div>}
                      />}
                    <CapsuleTab
                      icon="meeting_room"
                      name="meeting_rooms"
                      label="Seminarräume"
                      tooltip="Seminarräume"
                      description={formState.days.map(day => day.occupancy.length).reduce((a, b) => Math.min(a, b), 1000).toString() + " / " + formState.days.map(day => day.occupancy.length).reduce((a, b) => Math.max(a, b), 0).toString()}
                      popupShow={popupShow}
                      setPopupShow={setPopupShow}
                      expanded={popupExpanded}
                      capsuleLeftPosition={capsuleWrapperPositionLeft}
                      capsuleTopPosition={capsuleWrapperPositionTop}
                      capsuleWidth={capsuleWrapperWidth}
                      popupContent={
                        <MeetingRoomsSectionPopup
                          setFormState={setFormState}
                          formState={formState}
                          meetingRoomsDay={meetingRoomsDay}
                          setMeetingRoomsDay={setMeetingRoomsDay}
                          shadowRoot={shadowRoot}
                          availabilityChecker={availabilityChecker}
                        />
                      }
                    />
                    <CapsuleTab
                      icon="people"
                      name="particiants"
                      label="Gäste"
                      description={
                        Math.min(...formState.days.map(v => v.totalGuests)).toString() +
                        ' / ' +
                        Math.max(...formState.days.map(v => v.totalGuests)).toString()
                      }
                      setPopupShow={setPopupShow}
                      popupShow={popupShow}
                      expanded={popupExpanded}
                      capsuleLeftPosition={capsuleWrapperPositionLeft}
                      capsuleTopPosition={capsuleWrapperPositionTop}
                      capsuleWidth={capsuleWrapperWidth}
                      popupContent={<GuestSection setFormState={setFormState} formState={formState} />}
                    />
                      <CapsuleTab
                        name="rooms"
                        label="Zimmer"
                        icon="doublebed"
                        description={
                          `${Math.min(...formState.days.map(v => v.overnightGuests), formState.prevdayGuests)} / ${Math.max(
                            ...formState.days.map(v => v.overnightGuests),
                            formState.prevdayGuests,
                          )}` || 'nicht gesetzt'
                        }
                        setPopupShow={setPopupShow}
                        popupShow={popupShow}
                        expanded={popupExpanded}
                        capsuleLeftPosition={capsuleWrapperPositionLeft}
                        capsuleTopPosition={capsuleWrapperPositionTop}
                        capsuleWidth={capsuleWrapperWidth}
                        popupContent={<RoomsSection setFormState={setFormState} formState={formState} />}
                      />
                    
                    {!isHotelView && <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: '20px',
                      }}
                    >


                      <Button
                        color={'#AC2A6E'}
                        filled
                        iconPosition="left"
                        onClick={() => {
                          location.href = `${platformUrl}/?lat=${formState.placeLat}&lng=${formState.placeLng}&place=${formState.placeName}&s=${JSON.stringify(formState)}`
                        }}
                      >
                        <Icon name="search" color="white" />
                        Suchen
                      </Button>
                    </div>}
                  </CapsuleTabContainer>
                </>
            </BaseCapsule>
            {popupShow !== null && <ClickOverlay open={true} onClick={() => setPopupShow(null)} />}
          </>
      </div>
    </>
  );
};

export default Capsule;
